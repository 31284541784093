<template>
    <div>
      <!-- Page Heading -->
      <v-toolbar flat>
        <pageHeading pageTitle="Fulltime -- Payment Adjustment"/>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ on }">
              <v-btn 
                v-show="canDo && canDo.can.includes('add')"
                class="mx-2" depressed dark color="primary" v-on="on">
                <v-icon left dark>mdi-plus-circle</v-icon> Add
              </v-btn>
          </template>
          <!-- Modal Content -->
          <v-card>
            <v-card-title>
              <span class="headline">New Payment Adjustment</span>
            </v-card-title>

            <v-card-text>
              <v-form ref="form">
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12">

                        <v-menu
                            ref="menu_appiled_at"
                            v-model="menu.appiled_at"
                            :close-on-content-click="false"
                            :return-value.sync="newItem.appiled_at"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="newItem.appiled_at"
                                    label="Apply at"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    :rules="[validate_rules.required]"
                                ></v-text-field>
                                </template>
                                <v-date-picker
                                v-model="newItem.appiled_at"
                                no-title
                                scrollable
                                >
                                <v-spacer></v-spacer>
                                <v-btn
                                    text
                                    color="primary"
                                    @click="menu.appiled_at = false"
                                >
                                    Cancel
                                </v-btn>
                                <v-btn
                                    text
                                    color="primary"
                                    @click="$refs.menu_appiled_at.save(newItem.appiled_at)"
                                >
                                    OK
                                </v-btn>
                            </v-date-picker>
                        </v-menu>

                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="12">
                        <v-text-field label="Employee No" v-model="newItem.employee_no" :rules="[validate_rules.required]"> </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="12">
                        <v-select
                        v-model="newItem.schedule_id"
                        :items="schedules"
                        item-text="name"
                        item-value="id"
                        label="Schedule"
                        single-line
                        :rules="[validate_rules.required]"
                        ></v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="12">
                        <v-select
                        v-model="newItem.type"
                        :items="adjustment_types"
                        item-text="type"
                        item-value="value"
                        label="Type"
                        single-line
                        :rules="[validate_rules.required]"
                        ></v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="12">
                        <v-text-field label="Amount" v-model="newItem.amount" :rules="[validate_rules.number]"> </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="12">
                        <v-text-field label="Description" v-model="newItem.description"> </v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
              <v-btn v-if="canDo && canDo.can.includes('add')" color="blue darken-1" text @click="save">Add</v-btn>
            </v-card-actions>
          </v-card>
          <!-- End Modal Content -->
        </v-dialog>

        <v-dialog v-model="dialog_import" max-width="500px">
          <template v-slot:activator="{ on }">
              <v-btn 
                v-show="canDo && canDo.can.includes('import')"
                class="mx-2" depressed dark color="primary" v-on="on">
                <v-icon left dark>mdi-file-import</v-icon> Import
              </v-btn>
          </template>
          <!-- Modal Content -->
          <v-card>
            <v-card-title>
              <span class="headline">Import Adjustment From Payment Report</span>
            </v-card-title>

            <v-card-text>
              <v-form ref="form">
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12">
                        <v-select
                        v-model="importItem.schedule_id"
                        :items="schedules"
                        item-text="name"
                        item-value="id"
                        label="Schedule"
                        single-line
                        :rules="[validate_rules.required]"
                        ></v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="12">
                        <v-file-input
                            v-model="importItem.importFile"
                            :rules="[validate_rules.required,validate_rules.filesize,validate_rules.filetype]"
                            accept=".xlsx"
                            placeholder="Browse a xlsx file"
                            label="Payment Report"
                            @change="checkImportFile"
                        ></v-file-input>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
                <v-alert
                    dense
                    outlined
                    type="error"
                    v-if="importItem.import_file_error != ''" >
                Error :: {{ importItem.import_file_error }} 
                </v-alert>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeImport">Cancel</v-btn>
              <v-btn v-if="canDo && canDo.can.includes('import') && importItem.is_import_file_valid === true" color="blue darken-1" text @click="clickImport()">Import</v-btn>
            </v-card-actions>
          </v-card>
          <!-- End Modal Content -->
        </v-dialog>

      </v-toolbar> 



      <v-divider></v-divider>




      <div class="content-wrap">
            <v-row justify="end">
                <v-col cols="12" sm="5" md="4" >
                    <v-text-field
                        v-model="search.employee_keyword"
                        append-icon="mdi-magnify"
                        label="Employee No, name, phone"
                        single-line
                        hide-details
                    ></v-text-field>
                </v-col>
                <v-col cols="12" sm="5" md="4" >
                        <v-menu
                            ref="menu_search_appiled_at"
                            v-model="menu.search_appiled_at"
                            :close-on-content-click="false"
                            :return-value.sync="search.appiled_at"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="search.appiled_at"
                                    label="Apply at"
                                    prepend-icon="mdi-calendar"
                                    v-bind="attrs"
                                    readonly
                                    v-on="on"
                                ></v-text-field>
                                </template>
                                <v-date-picker
                                v-model="search.appiled_at"
                                no-title
                                scrollable
                                range
                                >
                                <v-spacer></v-spacer>
                                <v-btn
                                    text
                                    color="primary"
                                    @click="$refs.menu_search_appiled_at.save(null); menu.search_appiled_at = false; "
                                >
                                    Cancel
                                </v-btn>
                                <v-btn
                                    text
                                    color="primary"
                                    @click="$refs.menu_search_appiled_at.save(search.appiled_at)"
                                >
                                    OK
                                </v-btn>
                            </v-date-picker>
                        </v-menu>
                    
                </v-col>
                <v-col cols="2" >
                    <v-btn @click="searchItem()"
                        depressed dark color="primary" >
                        <v-icon left dark>mdi-account-search</v-icon> Search
                    </v-btn>
                </v-col>
            </v-row>
      <v-divider></v-divider>

           <v-row>
                
              <v-col cols="12" sm="12" md="12" >
                    <v-data-table
                        :headers="headers"
                        :items="adjustments"
                        :items-per-page="100"
                        :footer-props="footerProps"
                        class="elevation-1"
                        :sort-by="['paid_date']"
                        :sort-desc="[true]"
                        >
                            <template v-slot:item.appiled_at="{ item }">
                                <span>{{ moment(item.appiled_at).format('YYYY-MM-DD') }}</span>
                            </template>

                            <template v-slot:item.employee_no="{ item }">
                                <span>{{ item.employee.employee_no }}</span>
                            </template>

                            <template v-slot:item.name_th="{ item }">
                                <span>{{ item.employee.name_th }}</span>
                            </template>

                            <template v-slot:item.phone="{ item }">
                                <span>{{ item.employee.phone }}</span>
                            </template>

                            <template v-slot:item.type="{ item }">
                                <span>{{ lodash.filter(adjustment_types, {value : item.type })[0].type }}</span>
                            </template>


                            <template v-slot:item.schedule_name="{ item }">
                                <span>{{ item.fulltime_payment_schedule ? item.fulltime_payment_schedule.name : "---" }}</span>
                            </template>

                            <template v-slot:item.processed_at="{ item }">
                                <span>{{ item.fulltime_payment_schedule ? item.fulltime_payment_schedule.paid_date : "---" }}</span>
                            </template>

                            <template v-slot:item.actions="{ item }">

                                <v-icon small @click="deleteItem(item)" v-if="item.fulltime_payment_schedule.is_processed == null && canDo && canDo.can.includes('delete')">
                                    mdi-delete
                                </v-icon>
                            </template>
                    </v-data-table>
              </v-col>
          </v-row>
      </div>
    </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import pageHeading from '@/components/common/pageHeading.vue';

export default {
    name: 'FulltimePaymentSchedule',
    data: () => ( {
        dialog : false,
        dialog_import : false,
        adjustments : [],
        search : {
            employee_keyword : null,
            appiled_at : []
        },
        last_search : {
            employee_keyword : null,
            appiled_at : null
        },
        footerProps: {'items-per-page-options': [50, 100, 500]},
        importItem: {
            importFile : null,
            schedule_id : null,
            import_file_error : "",
            import_content : [],
            is_import_file_valid : false
        },
        headers: [
                    { text: 'ID', value: 'id', sortable: false, filterable: false },
                    { text: 'Appiled At', value: 'appiled_at' },
                    { text: 'Employee No', value: 'employee_no' },
                    { text: 'Employee Name', value: 'name_th' },
                    { text: 'Phone', value: 'phone' },
                    { text: 'Type', value: 'type' },
                    { text: 'Amount', value: 'amount' },
                    { text: 'Description', value: 'description' },
                    { text: 'Schedule', value: 'schedule_name' },
                    { text: 'Processed At', value: 'processed_at' },
                    { text: 'Delete', value: 'actions', sortable: false, filterable: false, }
            ],
        adjustment_types : [
            { value : 1, type : "Debit"},
            { value : 2, type : "Credit"},
            { value : 3, type : "Fix"},
        ],
        
        menu: {
            start_date: false,
        },
        newItem: {
            appiled_at: null,
            employee_no: null,
            amount: null,
            type: null,
            description: null,
            schedule_id: null
        },
        validate_rules : {
            required: v => !!v || 'This field is required',
            number: v => (!!v && Number.isFinite(parseFloat(v)) && parseFloat(v) > 0) || 'This field require a positive number ',
            filesize:  v => !v || v.size < 2048*1024*10 || 'Updload size should be less than 20 MB!',
            filetype:  v => {
                if(v){
                var ext = v.name.split('.').pop();
                return ["xlsx"].includes(ext) || 'Please upload .xlsx file only';
                } else {
                return 'This field is required'
                }
            }
        },
        schedules : []
    }),
    components: {
        pageHeading
    },
    methods: {
         ...mapActions(['genCan', 'addPaymentAdjustment', 'getPaymentAdjustment', 'deletePaymentAdjustment', 'getNonPaidPaymentSchedule', 'checkImportPaymentAdjustmentFile', 'ImportPaymentAdjustment']),
        async save() {
            if(this.$refs.form.validate())
            {
                let result = await this.addPaymentAdjustment(this.newItem);
                if (result.is_complete)
                {
                    alert("Insert complete");

                    this.search.employee_keyword = this.newItem.employee_no;
                    this.search.appiled_at = [this.newItem.appiled_at];
                    
                    this.adjustments = await this.getPaymentAdjustment(this.search);
                    this.last_search.employee_keyword = this.search.employee_keyword;
                    this.last_search.appiled_at = this.search.appiled_at;

                    this.newItem = {
                        appiled_at: null,
                        employee_no: null,
                        amount: null,
                        type: null,
                        description: null
                    }
                    this.close();
                }
                else
                {
                    alert(result.message);
                }
            }
        },
        async searchItem() {
            if (!this.search.employee_keyword  && !this.search.appiled_at )
            {
                alert("Please specific at least 1 conditions");
            }
            else
            {
                this.adjustments = await this.getPaymentAdjustment(this.search);
                this.last_search.employee_keyword = this.search.employee_keyword;
                this.last_search.appiled_at = this.search.appiled_at;
            }
        },
        close () {
            this.dialog = false
            setTimeout(() => {
                this.newItem = {
                    appiled_at: null,
                    employee_no: null,
                    amount: null,
                    type: null,
                    description: null
                }
                this.$refs.form.resetValidation() // prevent validate error show again when click add
            }, 300)
        },
        async deleteItem(item) {
            if (confirm("Confirm to delete schedule paid on " + item.appiled_at + " ?"))
            {
                let result = await this.deletePaymentAdjustment(item);
                if (result.is_complete)
                {
                    this.adjustments = await this.getPaymentAdjustment(this.last_search);
                    this.search.employee_keyword = this.last_search.employee_keyword;
                    this.search.appiled_at = this.last_search.appiled_at;
                }
                else
                    alert(result.message);
            }
        },
        async checkImportFile() {
            this.importItem.import_file_error = "";
            this.importItem.import_content = [];
            this.importItem.is_import_file_valid = false;

            let importCheckResult = await this.checkImportPaymentAdjustmentFile(this.importItem);
            console.log(importCheckResult);
            this.importItem.is_import_file_valid = importCheckResult.is_complete;
            this.importItem.import_file_error = importCheckResult.message;
            if (importCheckResult.is_complete)
                this.importItem.import_content = importCheckResult.import_content;
        },
        closeImport () {
            this.dialog_import = false
        },
        async clickImport() {
            let result = await this.ImportPaymentAdjustment(this.importItem)
            if (result.is_complete)
            {
                alert("Import complete");
                this.dialog_import = false;
            }
            else
            {
                alert("Error while import file");
                this.importItem.import_file_error = result.message;
            }
        }
    },
    async beforeMount() {
        this.genCan();
        this.schedules = await this.getNonPaidPaymentSchedule();
    },
    computed: {
        ...mapGetters(['canDo','allCategories'])
    },
    mounted() {
    }
};
</script>